<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div class="flex-1 bg-white pa-24 radius-20">

			<div class="under-line-bbb pb-16 flex-row">
				<h3 class="flex-1 size-px-20">{{  program.title }}</h3>
			</div>
			<!-- 커버등록 -->

			<div class="mt-30">
				<!-- 커버등록 -->
				<div
					class="radius-20 justify-center items-center bg-eee"
					style="height: 300px"
					:style="'background-image: url(' + item_cartel.img_src + '); background-size: cover; background-position: center center'"
				>
					<label
						class="box radius-100 pa-10"
					>
						<img
							:src="require('@/assets/image/web/notify_cartel/ico_set_photo.svg')"
						/>
						<input_file @change="setFile" accept="image/*" />
					</label>
				</div>
			</div>

			<div class="mt-20">
				<div>
					<label for="cartelName" class="h5 color-333">카르텔 이름</label>
					<div
						class="radius-20 pa-10-20 flex-row items-center mt-16"
						style="border:1px solid #dadada"
					>
						<input
							v-model="item_cartel.cartl_name"
							type="text" id="cartelName" placeholder="카르텔 이름을 입력하세요." maxlength="20"
							class="flex-1"
						>
						<v-icon
							v-if="item_cartel.cartl_name"
							@click="item_cartel.cartl_name = ''"
							class="mr-10 color-dadada"
						>mdi-close-circle</v-icon>
						<span style="flex-basis: 40px" class="text-right color-bbb">{{ item_cartel.cartl_name.length }}/20</span>
					</div>
				</div>
				<div class="mt-20">
					<label for="cartelDesc" class="h5 color-333">카르텔설명</label>
					<div
						class="radius-20 pa-10-20 flex-row items-center mt-16"
						style="border:1px solid #dadada"
					>
						<input
							v-model="item_cartel.cartl_desctn"
							type="text" id="cartelDesc" placeholder="카르텔에 대한 설명을 해주세요." maxlength="40"
							class="flex-1"
						>
						<v-icon
							v-if="item_cartel.cartl_desctn"
							@click="item_cartel.cartl_desctn = ''"
							class="mr-10 color-dadada"
						>mdi-close-circle</v-icon>
						<!-- TODO : 텍스트 수 카운트 필요 -->
						<span style="flex-basis: 40px" class="text-right color-bbb">{{ item_cartel.cartl_desctn.length }}/40</span>
					</div>
				</div>

				<hr class="under-line mt-20">

				<div class="mt-20">
					<div class="flex-row justify-space-between items-center">
						<h5 class="color-333">가입 승인</h5>
						<p class="toggle-group h_unset" @click="item_cartel.entry_approval_fg = !item_cartel.entry_approval_fg">
							<!-- TODO : 수신 허용시 .on 클래스 추가 -->
							<span class="btn-toggle" :class="{ on: !item_cartel.entry_approval_fg}"><em class="hide">가입 승인</em></span>
						</p>
					</div>
					<div class="color-888 size-px-14">
						보스, 또는 언더보스의 승인을 통해서만 카르텔 가입이 가능합니다.
					</div>
				</div>

				<hr class="under-line mt-20">

				<div class="mt-20">
					<h5 class="color-333">카르텔 관심사를 선택하세요.</h5>
					<ul class="mt-16">
						<li
							v-for="(int, i_index) in items_interest"
							:key="'int_' + i_index"
							class="cursor-pointer"
						>
							<div class="bright_radio interest_area">

								<input
									v-model="item_cartel.concern_sphere_code"
									:value="int.code"
									type="radio"
									:id="int.code"
								>
								<label
									:for="int.code"
								>
									<strong class="inline-block width-10">{{ int.label }}</strong>
									<p class="inline-block">{{  int.text }}</p>
								</label>
							</div>
						</li>
					</ul>
				</div>

				<div class="top-line mt-20 pt-20 text-right">
					<button
						class="btn-inline btn-primary radius-20 pa-10-30"
						@click="postCartel"
					>개설 요청</button>
				</div>


			</div>
		</div>
	</div>
</template>
<script>
	import MypageSide from "@/view/Mypage/mypage-lnb";
	import input_file from "@/components/InputFile"
	export default {
		name: 'mafia119'
		, components: {MypageSide, input_file}
		, props: ['callBack', 'user']
		, data: function(){
			return {
				program: {
					name: '카르텔 개설'
					, title: '카르텔 개설'
					, type: 'mypage'
					, not_footer: true
					, not_header: true
					, is_next: true
					, bg_contents: 'bg-gray01'
				}
				, item_cartel: {
					step: 1
					, cartl_name: ''
					, cartl_desctn: ''
					, entry_approval_fg: false
					, concern_sphere_code: ''
					, user_id: this.user.id
					, cartl_img_url: ''
				}
				, cartel_img: ''
				, upload_file: ''
				, items_interest: [
					{ name: 'music', code: 'CM00400001', label: 'Music', text: '음악을 좋아하고 관심을 가지고 있습니다.'}
					, { name: 'art', code: 'CM00400002', label: 'Art', text: '예술, 디자인등에 관심을 가지고 있습니다.'}
					, { name: 'fashion', code: 'CM00400003', label: 'Fashion', text: '패션 디자인, 모델 등에 관심을 가지고 있습니다.'}
					, { name: 'influence', code: 'CM00400004', label: 'Influence', text: '유튜버, 블로거 등을 운영하는것에 관심을 가지고 있습니다.'}
				]
			}
		}
		, methods: {

			setFile: function(e){

				this.cartel_img = e[0]
				this.$set(this.item_cartel, 'img_src', e[0])
				this.item_cartel.cartl_img_url = e[0]
			}

			, postFile: async function(){
				return true
			}
			, postCartel: async  function(){
				this.$log.console('postCartel', this.item_cartel)

				if(!this.item_cartel.img_src){
					this.$bus.$emit('notify', { type: 'error', message: '카르텔 이미지를 선택하세요'})
					return false
				}else if (!this.item_cartel.cartl_name || this.item_cartel.cartl_name == '') {
					this.$bus.$emit('notify', { type: 'error', message: '카르텔 이름을 입력하세요'})
					return false
				} else if (!this.item_cartel.cartl_desctn || this.item_cartel.cartl_desctn == '') {
					this.$bus.$emit('notify', { type: 'error', message: '카르텔 설명을 입력하세요'})
					return false
				}else if (!this.item_cartel.concern_sphere_code) {
					this.$bus.$emit('notify', { type: 'error', message: '카르텔 관심사를 선택하세요'})
					return false
				}

				if(await this.postFile()){
					try{
						this.$bus.$emit('on', true)
						const result = await this.$Request({
							method: 'post'
							, url: this.$api_url.api_path.post_cartel
							, data: {
								member_number: this.user.member_number
								, cartl_name: this.item_cartel.cartl_name
								, cartl_desctn: this.item_cartel.cartl_desctn
								, entry_approval_fg: !this.item_cartel.entry_approval_fg ? 'Y' : 'N'
								, concern_sphere_code: this.item_cartel.concern_sphere_code
								, cartl_img_url: this.item_cartel.cartl_img_url
							}
							, name: 'postCartel'
							, type: true
						})

						if(result.success){
							this.$emit('to', { name: 'mafia1211', params: {idx: result.data.id}})
						}else{
							throw result.message
						}

					}catch (e) {
						this.$log.console(e)
						this.$bus.$emit('notify', { type: 'error', message: e})
					}finally {
						this.$bus.$emit('on', false)
					}
				}
			}

			,goBack: function(){
				if(this.item_cartel.step == 1){
					this.$emit('goBack')
				}else{
					this.item_cartel.step = 1
				}
			}
		}
		,created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>

<style>
	.cover_background { background-color: #eee}

	ul li:nth-child(n+2){
		margin-top: 16px;
	}
</style>